import React, {useRef, useState, ReactNode} from "react";
import { Task, ITaskMetadata, HabitType, TaskMeasurement } from "../Task";

import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Modal from '@mui/material/Modal';
import ListItemText from '@mui/material/ListItemText';

import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import dayjs from "dayjs";
import { ITag, TagList, TagSelector } from "./TaskCategory"
import "../../styles/Tasks.css";
import "../../styles/App.css";

interface ITaskEditFormProps {
    task: Task,
    taskDetails: ITaskMetadata,
    setTaskDetails: (taskDetails: ITaskMetadata) => void,
    onSubmit?: () => void,
    onAbort?: () => void
}

export const TaskEditForm: React.FC<ITaskEditFormProps> = (props) => {
    const {task, taskDetails, setTaskDetails, onSubmit, onAbort} = props;

    const titleEditRef = useRef<HTMLInputElement>(null);
    const descEditRef = useRef<HTMLInputElement>(null);
    const dateEditRef = useRef<HTMLInputElement>(null);
    const repEditRef = useRef<HTMLInputElement>(null);
    const freqRef = useRef<HTMLInputElement>(null);
    const freqMultRef = useRef<HTMLInputElement>(null);
    const habitTypeRef = useRef<HTMLInputElement>(null);
    const measurementRef = useRef<HTMLInputElement>(null);
    const categoryEditRef = useRef<HTMLInputElement>(null);

    const updateCallback = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        task.title = titleEditRef.current?.value as string;
        task.description = descEditRef.current?.value as string;
        task.created = new Date(dateEditRef.current!.value);
        // task.repetitions = parseInt(repEditRef.current!.value);
        // const intervalUnit = parseInt(freqMultRef.current!.value);
        // const freqKey = Object.keys(TaskFrequency).find(
        //     (key: string) =>
        //     TaskFrequency[key as keyof typeof TaskFrequency] === freqRef.current?.value);
        // if (freqKey) {
        //     task.interval = {
        //         quantity: intervalUnit,
        //         unit: TaskFrequency[freqKey as keyof typeof TaskFrequency],
        //     }
        // }
        // const measurementKey = Object.keys(TaskMeasurement).find(
        //     (key: string) =>
        //     TaskMeasurement[key as keyof typeof TaskMeasurement] === measurementRef.current?.value);
        // if (measurementKey) {
        //    task.measurement = TaskMeasurement[measurementKey as keyof typeof TaskMeasurement];
        // }

        const habitTypeKey = Object.keys(HabitType).find(
            (key: string) =>
            HabitType[key as keyof typeof HabitType] === habitTypeRef.current?.value);
        if (habitTypeKey) {
           task.habitType = HabitType[habitTypeKey as keyof typeof HabitType];
        }

        const category = TagList.find(
            (category: ITag) => category.name === categoryEditRef.current?.value);
        if (category) {
           task.category = category;
        }

        setTaskDetails({
            title: task.title,
            description: task.description,
            status: task.status,
            created: task.created,
            repetitions: task.repetitions,
            interval: task.interval,
            measurement: task.measurement,
            habitType: task.habitType,
            category: task.category
        })
        task.save();
        if (onSubmit) { onSubmit(); }
    }

    return <>
          <form onSubmit={updateCallback}>
            <CardContent>
                <div className="taskEditForm">
                    <div className="taskEditField">
                        <TextField className="taskEditField" type="text" inputRef={titleEditRef} defaultValue={taskDetails.title}
                            variant="standard" label="Task title" autoFocus required/>
                    </div>
                    <div className="taskEditField">
                        <TextField className="taskEditField" type="text" multiline rows={3} inputRef={descEditRef}
                            defaultValue={taskDetails.description} label="Description"/>
                    </div>
                    <div className="taskEditField">
                        <TagSelector defaultValue={taskDetails.category} ref={categoryEditRef} />
                        {/* <FormControl sx={{width: "50%"}}>
                            <InputLabel id="demo-multiple-checkbox-label">Category</InputLabel>
                            <Select
                              defaultValue={taskDetails.category?.name}
                              label="Category"
                              inputRef={categoryEditRef}
                            >
                              { TagList.map((tag) => (
                                <MenuItem key={tag.name} value={tag.name}>
                                  <ListItemText primary={tag.name} />
                                </MenuItem>
                              )) }
                            </Select>
                        </FormControl> */}
                    </div>
                    <div className="taskEditField">
                      <FormControl sx={{width: "50%"}} >
                        <InputLabel>Type</InputLabel>
                        <Select
                          defaultValue={taskDetails.habitType}
                          label="Type"
                          inputRef={habitTypeRef}
                        >
                            {Object.keys(HabitType).map((key: string, index: number) =>
                                <MenuItem key={index} value={HabitType[key as keyof typeof HabitType]}>{key}</MenuItem>
                            )}
                        </Select>
                      </FormControl>
                      <FormControl
                            sx={{width: "50%"}} >
                        <InputLabel>Measurement</InputLabel>
                        <Select
                          defaultValue={taskDetails.measurement}
                          label="Measurement"
                          inputRef={measurementRef}
                        >
                            {Object.keys(TaskMeasurement).map((key) =>
                                <MenuItem value={TaskMeasurement[key as keyof typeof TaskMeasurement]}>{key}</MenuItem>
                            )}
                        </Select>
                      </FormControl>
                    </div>
                    {/* <div className="taskEditField">
                        <TextField
                            sx={{width: "33%"}}
                          label="Repetitions"
                          type="number"
                          defaultValue={taskDetails.repetitions}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{ inputProps: { min: 0 } }}
                          inputRef={repEditRef}
                        />
                        <TextField
                            sx={{width: "33%"}}
                              label="Every"
                              type="number"
                              InputProps={{ inputProps: { min: 0 } }}
                              defaultValue={taskDetails.interval.quantity}
                              InputLabelProps={{ shrink: true }}
                              inputRef={freqMultRef}
                        />
                      <FormControl
                            sx={{width: "33%"}} >
                        <InputLabel id="demo-simple-select-label">Period</InputLabel>
                        <Select
                          defaultValue={taskDetails.interval.unit}
                          label="Frequency"
                          inputRef={freqRef}
                        >
                            {Object.keys(TaskFrequency).map((key) =>
                                <MenuItem value={TaskFrequency[key as keyof typeof TaskFrequency]}>{key}</MenuItem>
                            )}
                        </Select>
                      </FormControl>
                    </div> */}
                    <div className="taskEditField">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Started"
                                defaultValue={dayjs(taskDetails.created.toDateString())}
                                views={['year', 'month', 'day']}
                                inputRef={dateEditRef}
                            />
                        </LocalizationProvider>
                    </div>
                </div>
            </CardContent>
            <CardActions className="taskEditModalActions">
                <Button size="small" type="submit" variant="outlined" startIcon={<SaveIcon />}>
                    Save
                </Button>
                <Button size="small" onClick={onAbort} variant="outlined" startIcon={<CancelIcon />}>
                    Cancel
                </Button>
            </CardActions>
        </form>
    </>;
}

interface ITaskEditModalProps {
    task: Task,
    taskDetails: ITaskMetadata,
    setTaskDetails: (taskDetails: ITaskMetadata) => void,
    children: ReactNode
}

export const TaskEditModal: React.FC<ITaskEditModalProps> = (props) => {
    const {task, taskDetails, setTaskDetails, children} = props;

    const [open, setOpen] = useState(false);
    const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
        setOpen(true);
    }
    const handleClose = () => setOpen(false);

    return <>
        <div onClick={handleOpen}>
            { children }
        </div>
        <Modal
            open={open}
            onClose={handleClose}>
            <Box className="modalBox" sx={{
              width: 800,
              bgcolor: 'background.paper',
              boxShadow: 24, p: 4,
            }}>
                <TaskEditForm task={task} taskDetails={taskDetails} setTaskDetails={setTaskDetails}
                    onSubmit={handleClose} onAbort={handleClose} />
            </Box>
      </Modal>
    </>;
}
