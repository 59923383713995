import { useCallback, useEffect, useState } from 'react';
import { CookiesProvider, useCookies } from 'react-cookie';
import '../styles/App.css';
import { GUEST_ID, load, loadImportedTags } from "./Loader/Persist";
import PageLayoutFrame from "./PageLayout";
import { Task } from "./Task";
import { LoginModal } from "./User/LoginModal";


function App() {
    const [cookies, setCookie] = useCookies(["user", "sidebarOpen"]);
    const [dataFetched, setDataFetched] = useState(false);
    const [data, setData] = useState<Task[]>([]);
    const [newSession, setNewSession] = useState(false);

    useEffect(() => {
      document.title = "HabitSelf";
    }, [])

    useEffect(() => {
        if (dataFetched) { return; }
        if (!cookies.user) { return; }
        if (cookies.user === GUEST_ID) { setData([]); return; }

        fetch(`/api/${cookies.user}/categories/get`)
          .then((res) => res.json())
          .then((loadedData) => {
            loadImportedTags(loadedData);
            return fetch(`/api/${cookies.user}/tasks/get`);
          })
          .then((res) => res.json())
          .then((loadedData) => {
            const taskData = load(loadedData);
            if (newSession) {
                localStorage.setItem("activeTasks", JSON.stringify(taskData.map((task: Task) => task.id)));
            }
            setData(taskData);
            setDataFetched(true);
        });

        return () => {
            setData([]);
        };
    }, [cookies.user, setData, setDataFetched, newSession]);
    
    const handleLogin = useCallback((userID: string) => {
        setCookie("user", userID, { path: "/" });
        setData([]);
        setNewSession(true);
    }, [data, setData]);

    const handleLogout = () => {
        setCookie("user", "", { path: "/" });
        setCookie("sidebarOpen", false, { path: "/" });
        
        localStorage.setItem("activeTasks", "[]");
        setNewSession(false);
        setDataFetched(false);
    }

    return (
      <CookiesProvider>
          <div className="App">
              { !cookies.user && <LoginModal setUser={handleLogin}/> }
              { cookies.user && dataFetched && data && <PageLayoutFrame logout={handleLogout} data={data} setData={setData} /> }
          </div>
      </CookiesProvider>
    );
}

export default App;