import { useEffect, useMemo, useRef } from 'react';

import * as d3 from "d3";
import ColorPicker from "../utility/ColorPicker";

export const FrequencyGraphWrapper = (props) => {
  const graph = useMemo(() => <FrequencyGraph {...props} />, [props.tasks])
  return graph;
}

export const FrequencyGraph = (props) => {
    const { tasks } = props;
    const svg = useRef(null);

    // Chart visual
    const width = 1000;
    const height = 500;
    const marginTop = 20;
    const marginRight = 30;
    const marginBottom = 30;
    const marginLeft = 100;

    // Chart data
    const data = [];

    const refWeek = new Date();
    refWeek.setDate(refWeek.getDate() - 35);

    for (const task of tasks) {
        data.push(task.history.filter((event) => event.date >= refWeek));
    }

    const dayExtent = [refWeek, new Date()]
    const dayScale = d3.scaleTime()
        .domain(dayExtent)
        .nice(d3.timeWeek);
    
    const bin = d3.bin()
        .value(d => d.date) // Use the date as the value
        .domain(dayScale.domain())  // Set the domain for the bins (min and max date)
        .thresholds(dayScale.ticks(d3.timeWeek));

    const freqData = data
        .map((task) => bin(task))
        .map((distribution) => 
            distribution.map((bin) => ({ date: bin.x0, count: bin.length }))
        );

    const xScale = d3.scaleUtc()
        .domain(dayExtent)
        .range([marginLeft, width - marginRight]);
      
    const yScale = d3.scaleLinear()
        .domain([0, 7])
        .range([height - marginBottom, marginTop]);

    const lineGenerator = d3.line()
        .x(d => xScale(d.date))
        .y(d => yScale(d.count));

    const byTitle = new Map();
    freqData.forEach((series, i) => {
        byTitle.set(tasks[i].id, series)
    });

    // lines
    useEffect(() => {
      d3.select("#svg").append('g')
          .attr("transform", `translate(0,${height - marginBottom})`)
          .call(d3.axisBottom(xScale).ticks(10));
        
      d3.select("#svg").append('g')
          .attr("transform", `translate(${marginLeft},0)`)
          .call(d3.axisLeft(yScale)
            .ticks(7) )
          .call(g => g.selectAll(".tick line").clone()
            .attr("x2", width - marginLeft - marginRight)
            .attr("stroke-opacity", 0.4));
      
      const serie = d3.select("#svg").append("g")
        .selectAll()
        .data(byTitle)
        .join("g");

      var color = d3.scaleOrdinal()
        .domain(tasks.map((task) => task.id))
        .range(tasks.map((task) => ColorPicker.getColor(task)));

      // Draw the lines.
      serie.append("path")
          .attr("fill", "none")
          .attr("stroke", d => color(d[0]))
          .attr("stroke-width", 1.5)
          .attr("d", d => lineGenerator(d[1]));
      
      serie.append("g")
          .attr("stroke", d => color(d[0]))
          .attr("fill", d => color(d[0]))
          .attr("stroke-width", 2.5)
        .selectAll("circle")
        .data(d => d[1])
        .join("circle")
          .attr("cx", d => xScale(d.date))
          .attr("cy", d => yScale(d.count))
          .attr("r", 2.5);

      const legend = d3.select("#svg").selectAll(".legend")
        .data(byTitle)
        .enter().append("g")
        .attr("class", "legend")
        .attr("transform", (d, i) => `translate(0,${i * 20})`);
      
      legend.append("rect")
        .attr("x", width - 18)
        .attr("width", 18)
        .attr("height", 18)
        .style("fill", d => color(d[0]));
      
      legend.append("text")
        .attr("x", width - 24)
        .attr("y", 9)
        .attr("dy", ".35em")
        .attr("fill", "white")
        .style("text-anchor", "end")
        .text(d => tasks.find((task) => task.id === d[0])?.title);
    }, [])
  
    // // Add the event listeners that show or hide the tooltip.
    // const bisect = d3.bisector(d => d.Date).center;
    // function pointermoved(event) {
    //   const i = bisect(aapl, x.invert(d3.pointer(event)[0]));
    //   tooltip.style("display", null);
    //   tooltip.attr("transform", `translate(${x(aapl[i].Date)},${y(aapl[i].Close)})`);

    //   const path = tooltip.selectAll("path")
    //     .data([,])
    //     .join("path")
    //       .attr("fill", "white")
    //       .attr("stroke", "black");

    //   const text = tooltip.selectAll("text")
    //     .data([,])
    //     .join("text")
    //     .call(text => text
    //       .selectAll("tspan")
    //       .data(["A", "B"])
    //       .join("tspan")
    //         .attr("x", 0)
    //         .attr("y", (_, i) => `${i * 1.1}em`)
    //         .attr("font-weight", (_, i) => i ? null : "bold")
    //         .text(d => d));

    //   size(text, path);
    // }

    // function pointerleft() {
    //   tooltip.style("display", "none");
    // }

    // // Wraps the text with a callout path of the correct size, as measured in the page.
    // function size(text, path) {
    //   const {x, y, width: w, height: h} = text.node().getBBox();
    //   text.attr("transform", `translate(${-w / 2},${15 - y})`);
    //   path.attr("d", `M${-w / 2 - 10},5H-5l5,-5l5,5H${w / 2 + 10}v${h + 20}h-${w + 20}z`);
    // }
    return  <svg id="svg" width={width} height={height}> </svg>
}