import React, { useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import { ISubscriber, ISubscriberCallback } from "./Subscription";

import { CalendarControl } from "./Calendar/CalendarControl";
import { Task } from "./Task";
import { TaskEvent, TaskEventEditor } from "./TaskEvent";

import ColorPicker from "./utility/ColorPicker";
import { compareDates } from "./utility/Dates";

import "../styles/App.css";
import '../styles/Dashboard.css';
import { FrequencyGraphWrapper } from './Graph/FrequencyGraph';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

/**
 *
 **/

export class TaskDashboardManager implements ISubscriber {
    subscriptions: Task[];
    subscriptionCallbacks: Map<string, ISubscriberCallback>;

    constructor(tasks: Task[] = []) {
        this.subscriptions = tasks;
        this.subscriptionCallbacks = new Map();
    }

    /**
    *       Accessors / mutators
    **/
        get tasks(): Task[] { return this.subscriptions; }
        private set tasks(tasks: Task[]) { this.subscriptions = tasks; }

    /**
     *      Subscriptions
     **/

        // Add an event source to listen to
        private _triggerCallbacks() {
            Array.from(this.subscriptionCallbacks.values()).forEach(
                (callback: ISubscriberCallback) => callback(null)
            );
        }

        public subscribeToTask(task: Task) {
            Array.from(this.subscriptionCallbacks.keys()).forEach((key: string) => {
                const callback = this.subscriptionCallbacks.get(key)!;
                task.subscribe(callback, key);
            });
            this.tasks.push(task);
            this._triggerCallbacks();
        }

        public unsubscribeFromTask(task: Task) {
            const index = this.tasks.indexOf(task, 0);
            if (index >= 0) {
                this.tasks.splice(index, 1);
            }
            this._triggerCallbacks();
        }

        public addSubscriptionCallback(callback: ISubscriberCallback, key: string): void {
            this.tasks.forEach((task: Task) => {
                task.subscribe(callback, key);
            });

            this.subscriptionCallbacks.set(key, callback);
        }

        public removeSubscriptionCallback(key: string): void {
            this.tasks.forEach((task: Task) => {
                task.unsubscribe(key);
            })
            this.subscriptionCallbacks.delete(key);
        }
}

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
  }
  
function TabPanel(props: TabPanelProps) {
const { children, value, index, ...other } = props;

return (
    <div
    role="tabpanel"
    hidden={value !== index}
    id={`full-width-tabpanel-${index}`}
    aria-labelledby={`full-width-tab-${index}`}
    {...other}
    >
    {value === index && (
        <Box >
            <Typography>{children}</Typography>
        </Box>
    )}
    </div>
);
}

interface ITaskDashboardProps {
    manager: TaskDashboardManager,
    activeTasks: Task[]
}

export const TaskDashboard: React.FC<ITaskDashboardProps> = (props: ITaskDashboardProps) => {
    const { manager, activeTasks } = props;
    const [value, setValue] = React.useState(0);
  
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return <>
    <Box sx={{ width: '100%' }}>
        <Tabs value={value} onChange={handleChange} centered>
            <Tab label="Calendar" sx={{color: 'white'}}/>
            <Tab label="Event Log" sx={{color: 'white'}}/>
            <Tab label="Graph" sx={{color: 'white'}}/>
        </Tabs>
            <TabPanel value={value} index={0} >
                <CalendarControl tasks={activeTasks} />
            </TabPanel>
            <TabPanel value={value} index={1} >
                <TaskEventLog manager={manager} tasks={activeTasks} />
            </TabPanel>
            <TabPanel value={value} index={2} >
               <FrequencyGraphWrapper tasks={activeTasks} />
            </TabPanel>
        </Box>
    </>;
}

interface ITaskEventLogEntryProps {
    taskEvent: TaskEvent,
}

export const TaskEventLogEntry: React.FC<ITaskEventLogEntryProps> = (props: ITaskEventLogEntryProps) => {
    const { taskEvent } = props;
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <div className="taskEventLogEntry" onClick={handleOpen}>
                <span className="editButton"> <EditIcon /> </span>
                <span className="eventLogEntryTitle">{ taskEvent.toString() }</span>
                <Typography variant="body1" className="eventLogEntryDesc" sx={{ fontSize: 20 }}>
                    {taskEvent.comment}
                </Typography>
            </div>
            { open && <TaskEventEditor taskEvent={taskEvent} open={open} handleClose={handleClose} /> }
        </>
    );
}

const TaskEventLog: React.FC<{manager: TaskDashboardManager, tasks: Task[]}> = ({manager, tasks}) => {
    const monthStartDate = new Date();
    monthStartDate.setDate(1);
    const monthEndDate = new Date(monthStartDate.getFullYear(), monthStartDate.getMonth() + 1, 1);

    return <>
        {tasks.map(
            (task: Task, index: number) =>
                <Accordion key={index}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      sx={{ backgroundColor: ColorPicker.getColor(task),
                        color: ColorPicker.getNeutralColor(task) }}
                    >
                    { task.title }
                    </AccordionSummary>
                    <AccordionDetails className="tasklogBody">
                    { task.history.length == 0 && "No data"}
                    { [...task.history].reverse().filter(
                        (event: TaskEvent) => 
                            compareDates(event.date, monthStartDate) >= 0 && compareDates(event.date, monthEndDate) < 0
                    ).map(
                        (event: TaskEvent, index: number) =>
                        <div key={index}>
                            <TaskEventLogEntry taskEvent={event}/>
                        </div>
                    )}
                    </AccordionDetails>
                </Accordion>
        )}
    </>;
}
